import AppFooter from "@/components/AppFooter";
import { AppHeader } from "@/components/AppHeader";
import { Services } from "@/components/service-sections/Services";
import { Slider } from "@/components/ui-components/Slider";
import { Client, ClientService } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";

export function SingleClientScreen({
  className,
  client,
  clientDefault,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  client: Client;
  clientDefault: Client;
}) {
  // const { setCurrentClient } = useAppContext();
  // const { setClientDefault } = useAppContext();
  //
  const [service, setService] = useState<ClientService | undefined>();
  const [serviceDefault, setServiceDefault] = useState<ClientService | undefined>();

  useEffect(() => {
    if (client && clientDefault && client?.services?.filter((service) => service.isShow).length == 1) {
      let currentService = client?.services?.find((service) => service.isShow);
      let defaultService = clientDefault?.services?.find(
        (service) => service.slide.slug == currentService?.slide.slug
      );
      setService(currentService);
      setServiceDefault(defaultService);
    }
  }, [client, clientDefault, service, serviceDefault]);

  return (
    <div className={classNames("relative min-h-screen", className ?? "")} {...rest}>
      <AppHeader logo={client.logo} slug={client.slug} />
      <div className="">
        <div className="container relative mb-[31px] grid grid-cols-1 gap-3 sm:mb-[51px] sm:gap-5 lg2:grid-cols-2">
          <div className="relative flex flex-col items-start justify-center lg2:pl-8">
            <h1
              className={classNames(`relative float-left mb-2.5 text-[32px] font-medium capitalize
              before:absolute before:-right-4 before:top-10 before:h-[20px]
              before:w-[140px] before:bg-[url('/images/home-underscore.svg')] before:bg-cover before:bg-center before:bg-no-repeat
              before:max-[639px]:right-0 before:max-[639px]:top-6 before:max-[639px]:w-[120px] before:max-[639px]:bg-contain
              before:max-[375px]:-right-1 before:max-[375px]:top-5 before:max-[375px]:w-[100px]
              sm:mb-8 sm:text-[40px] sm:before:-right-2 sm:before:top-6 sm:before:w-[170px]
              sm:before:bg-contain
              xl:text-6xl-custom xl:before:-right-3 xl:before:top-[52px]
              xl:before:w-[226px] xl:max-[1440px]:text-5xl xl:max-[1440px]:before:top-10 xl:max-[1440px]:before:w-[200px]
              `)}>
              <Trans i18nKey={client?.title1 ?? "home_main_block_title_1"} />
            </h1>
            <h1
              className="relative float-left mb-4 text-[32px] font-medium capitalize
              after:absolute after:h-[50px] after:w-[50px]
              after:bg-[url('/images/home-underscore-lines.svg')] after:bg-contain after:bg-center after:bg-no-repeat
              after:max-[639px]:-right-5 after:max-[639px]:top-3 after:max-[639px]:h-[40px] after:max-[639px]:w-[40px]
              after:max-[375px]:-right-4 after:max-[375px]:top-2 after:max-[375px]:w-[30px] sm:mb-8
              sm:text-[40px] sm:after:-right-6 sm:after:top-2
              xl:text-6xl-custom xl:after:-right-6 xl:after:top-7 xl:max-[1440px]:text-5xl xl:max-[1440px]:after:top-4
              ">
              <Trans i18nKey={client?.title2 ?? "home_main_block_title_2"} />
            </h1>
            <p className="text-lg !font-normal leading-[26px] sm:leading-[40px] xl:text-2xl-custom">
              <Trans
                i18nKey={client?.description ?? clientDefault?.description ?? "home_main_block_description"}
              />
            </p>
          </div>
          <div className="relative flex justify-center lg2:justify-end ">
            <Image
              className="h-full  max-h-[695px] w-full rounded-[40px] md:max-w-[609px] "
              src={client.img || "/images/home.png"}
              alt={client?.title1 ?? ""}
              width="609"
              height="695"
              placeholder="blur"
              blurDataURL="/images/blur.png"
            />
            <div className="absolute bottom-4 flex w-[650px]  flex-row flex-wrap items-center justify-center gap-[10px] sm:gap-5 lg:w-[920px] lg2:bottom-[40px] lg2:right-11">
              <p className="flex min-h-[43px] items-center justify-center rounded-full bg-brand-green-200 px-5 py-2 text-base font-semibold text-brand-text-white lg:text-2xl">
                <Trans i18nKey={client?.tagMessages?.tag_message_1 ?? "tag_message_1"} />
              </p>
              <div className="flex max-h-[43px] min-h-[43px] items-center rounded-full bg-brand-blue-700 px-5 py-1 sm:max-h-none  md:px-4 md:py-2">
                <Image
                  className="max-h-[24px] sm:max-h-[36px]"
                  src="/images/icons/message.svg"
                  width="36"
                  height="36"
                  alt=""
                />
              </div>
              <p className="flex min-h-[43px] items-center justify-center rounded-full bg-brand-blue-200 px-5 py-1 text-base font-semibold text-brand-text-white md:px-4 md:py-2 lg:text-2xl">
                <Trans i18nKey={client?.tagMessages?.tag_message_2 ?? "tag_message_2"} />
              </p>
              <div className="flex max-h-[43px] min-h-[43px] items-center rounded-full bg-brand-orange-400 px-4  py-2 sm:max-h-none">
                <Image
                  className="max-h-[24px] w-auto sm:max-h-[36px]"
                  src="/images/icons/head.png"
                  width={36}
                  height={36}
                  alt=""
                />
              </div>
              <p className="flex min-h-[43px] items-center justify-center rounded-full bg-brand-green-200 px-5 py-1 text-base font-semibold text-brand-text-white md:px-4 md:py-2 lg:text-2xl">
                <Trans i18nKey={client?.tagMessages?.tag_message_3 ?? "tag_message_3"} />
              </p>
              <div className="flex max-h-[43px] min-h-[43px] items-center rounded-full bg-brand-blue-700 px-4  py-2 sm:max-h-none">
                <Image
                  className="max-h-[24px] sm:max-h-[36px]"
                  src="/images/icons/apple.svg"
                  width="36"
                  height="36"
                  alt=""
                />
              </div>
              <p className="flex min-h-[43px] items-center justify-center rounded-full bg-brand-orange-400 px-5 py-1 text-base font-semibold text-brand-text-white md:px-4 md:py-2 lg:text-2xl">
                <Trans i18nKey={client?.tagMessages?.tag_message_4 ?? "tag_message_4"} />
              </p>
              <div className="flex max-h-[43px] min-h-[43px] items-center rounded-full bg-brand-green-200 px-4  py-2 sm:max-h-none">
                <Image
                  className="max-h-[24px] sm:max-h-[36px]"
                  src="/images/icons/plus-white.svg"
                  width="36"
                  height="36"
                  alt=""
                />
              </div>
              <p className="flex min-h-[43px] items-center justify-center rounded-full bg-brand-blue-700 px-5 py-1 text-base font-semibold text-brand-text-white md:px-4 md:py-2 lg:text-2xl">
                <Trans i18nKey={client?.tagMessages?.tag_message_5 ?? "tag_message_5"} />
              </p>
              <div className="hidden max-h-[43px] min-h-[43px] items-center rounded-full bg-brand-blue-200 px-4 py-2 sm:flex sm:max-h-none">
                <Image
                  className="max-h-[24px] sm:max-h-[36px]"
                  src="/images/icons/notebook.svg"
                  width="36"
                  height="36"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {Array.isArray(client.services) &&
          client?.services?.filter((service) => service.isShow).length == 1 && (
            <div className={classNames(`pt-[32px] sm:pt-[70px]`)}>
              <Services service={service} serviceDefault={serviceDefault} />
            </div>
          )}
        {Array.isArray(client.services) &&
          client?.services?.filter((service) => service.isShow).length > 1 && (
            <div className={classNames(`bg-brand-blue-100 pb-[30px] pt-[32px] sm:pt-[70px]`)}>
              <h1 className="container mb-7 text-[28px] font-medium capitalize sm:mb-8 sm:text-5xl">
                <Trans i18nKey={client?.sliderTitle ?? "home_slider_block_title"} />
              </h1>
              <Slider className="swiper-1" clientSlug={client.slug} services={client.services} />
            </div>
          )}
      </div>

      <div className="m-0 bg-brand-blue-100 py-[30px]">
        <div className="container flex flex-row flex-wrap justify-between">
          {client?.summaryPlanDocumentLink != "" ? (
            <div className="flex flex-row flex-wrap justify-start">
              <p className="mb-4 mr-2 text-base sm:mb-0">
                <Trans i18nKey={client?.toConfirmBenefits ?? "to_confirm_benefits"} />
                <Link
                  href={`${client?.summaryPlanDocumentLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  locale={false}
                  className="relative mx-2 text-base font-semibold text-brand-text-blue-light after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-brand-text-blue-light after:transition-all after:duration-300 hover:after:w-full">
                  <Trans i18nKey={client?.summaryPlanDocumentTitle ?? "summary_plan_document_title"} />
                </Link>
              </p>
            </div>
          ) : (
            <></>
          )}
          {client?.checkMyBenefitsLink != "" ? (
            <Link
              href={`${client?.checkMyBenefitsLink}`}
              target="_blank"
              rel="noopener noreferrer"
              locale={false}
              className="relative text-base font-semibold text-brand-text-blue-light after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-brand-text-blue-light after:transition-all after:duration-300 hover:after:w-full">
              <Trans i18nKey={client?.checkMyBenefitsTitle ?? "check_my_benefits_title"} />
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <AppFooter footer={client.footer} />
    </div>
  );
}
