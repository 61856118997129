import { BlockHeader } from "@/components/ui-components/BlockHeader";
import { BehavioralHealthForms } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export const SectionBehavioralHealthForms = ({
  className,
  data,
  slug,
  dataDefault,
  classBgColor,
  classTextColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: BehavioralHealthForms | undefined;
  dataDefault?: BehavioralHealthForms | undefined;
  classBgColor: string | undefined;
  classTextColor: string | undefined;
  slug?: string | undefined;
}) => {
  return (
    <div className={classNames("", className ?? "")} {...rest}>
      {data && (
        <div className={classNames("p-4 sm:p-10", classBgColor)}>
          <div className="lg:container lg:mx-auto">
            <BlockHeader
              title={data?.title ?? dataDefault?.title ?? ""}
              description=""
              classTextColor={classTextColor}
            />
            <p
              className={classNames(
                "mx-auto text-left text-base sm:mt-4 sm:text-2xl sm:font-semibold",
                `${classTextColor}`
              )}>
              <Trans i18nKey={data?.description ?? dataDefault?.description ?? ""} />
            </p>
            {data.items.map((item, index) => (
              <div
                key={index}
                className={classNames(
                  "icon-before-text relative mt-6 flex flex-col gap-6 rounded-2xl bg-brand-bg-default py-6 pr-2 before:left-4 before:h-9 before:w-9 before:rounded-full before:p-6 sm:flex-row lg:pr-10"
                )}>
                <div
                  className={classNames(
                    "hidden h-16 w-16 flex-shrink-0 items-center justify-center self-center rounded-full sm:flex sm:self-center",
                    classBgColor
                  )}>
                  <Image
                    className={classNames("")}
                    src={item?.icon ?? dataDefault?.items[index]?.icon ?? "/images/icons/hands-green.svg"}
                    alt={item?.title ?? dataDefault?.items[index]?.title ?? ""}
                    width="34"
                    height="34"
                  />
                </div>
                <div>
                  <div className="flex flex-row gap-6">
                    <div
                      className={classNames(
                        "flex h-16 w-16 flex-shrink-0 items-center justify-center self-center rounded-full sm:hidden sm:self-center",
                        classBgColor
                      )}>
                      <Image
                        className={classNames("")}
                        src={item?.icon ?? dataDefault?.items[index]?.icon ?? "/images/icons/hands-green.svg"}
                        alt={item?.title ?? dataDefault?.items[index]?.title ?? ""}
                        width="34"
                        height="34"
                      />
                    </div>
                    <h3
                      className={classNames(
                        "py-4l w-full self-center text-left text-xl font-semibold sm:text-2xl"
                      )}>
                      <Trans i18nKey={item?.title ?? dataDefault?.items[index]?.title ?? ""} />
                    </h3>
                  </div>

                  <p className="my-4 text-left text-lg font-light">
                    <Trans i18nKey={item?.description ?? dataDefault?.items[index]?.description ?? ""} />
                  </p>
                  <Link
                    className={classNames(
                      `relative cursor-pointer text-left text-base font-semibold text-brand-text-green after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:transition-all after:duration-300 hover:after:w-full sm:text-xl`,
                      themeColors[slug as keyof typeof themeColors].afterBg
                    )}
                    href={`${item.formUrl}`}
                    target="_blank">
                    <Trans i18nKey="view_form" defaults="View Form" />
                  </Link>
                </div>
              </div>
            ))}
            <p className={classNames("mb-4  mt-6 text-center text-xs font-light", `${classTextColor}`)}>
              <Trans i18nKey={data?.footerText1 ?? dataDefault?.footerText1 ?? ""} />
            </p>
            <p className={classNames("text-center text-xs font-light", `${classTextColor}`)}>
              <Trans i18nKey={data?.footerText2 ?? dataDefault?.footerText2 ?? ""} />
            </p>
            <p className={classNames("text-center text-xs font-light", `${classTextColor}`)}>
              <Trans i18nKey={data.footerText3 ?? dataDefault?.footerText3 ?? ""} />
            </p>
            <p className={classNames("text-center text-xs font-light", `${classTextColor}`)}>
              <Trans i18nKey={data.footerText4 ?? dataDefault?.footerText4 ?? ""} />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
