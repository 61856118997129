import { SectionListForms } from "@/components/service-sections/SectionListForms";
import { FileLink } from "@/components/ui-components/FileLink";
import { FormItem, Row, Tabs } from "@/lib/types";
import themeColors from "@/styles/themeSettings";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Link from "next/link";
import React, { useState, useRef, useEffect } from "react";

export const SectionTabs = ({
  className,
  data,
  slug,
  dataDefault,
  classBgColor,
  classBorderColor,
  classMainColor,
  classTextColor,
  classFillColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: Tabs | undefined;
  slug?: string | undefined;
  dataDefault?: Tabs | undefined;
  classBgColor: string | undefined;
  classBorderColor: string | undefined;
  classMainColor: string | undefined;
  classTextColor: string | undefined;
  classFillColor: string | undefined;
}) => {
  const [activeTab, setActiveTab] = useState(data?.activeTab ?? 0);
  const [activeButton, setActiveButton] = useState(data?.activeTab ?? 0);
  const panelRef = useRef(null);

  useEffect(() => {
    if (activeButton !== null && panelRef.current) {
      const panel = panelRef.current as HTMLDivElement;
      const button = panel.children[activeButton] as HTMLButtonElement;
      const panelWidth = panel.offsetWidth;
      const buttonOffset = button.offsetLeft + button.offsetWidth / 2;
      const offset = buttonOffset - panelWidth / 2 + 270;
      panel.style.transform = window.matchMedia("(max-width: 1024px)").matches
        ? `translateX(${-offset}px)`
        : `translateX(0px)`;
    }
  }, [activeButton, window.innerWidth]);
  const handleClick = (e: any, newActiveTab: number) => {
    e.preventDefault();
    setActiveTab(newActiveTab);
    setActiveButton(newActiveTab - 1);
  };

  return (
    <div className={classNames("relative mt-10 py-5 sm:py-10 ", classBgColor, className ?? "")} {...rest}>
      {data && data?.items?.length > 0 && (
        <div className="container mx-auto flex flex-col pt-[60px] lg:!max-w-[1160px] lg:pt-0 ">
          <div
            ref={panelRef}
            className={classNames(
              "absolute top-6 flex min-h-14 flex-nowrap items-center justify-center gap-1 rounded-[3.5rem] border p-2 lg:relative lg:top-auto lg:min-h-full lg:gap-2",
              themeColors[slug as keyof typeof themeColors].borderColor20
            )}>
            {data?.items?.map((item, index) => (
              <button
                key={index + 1}
                className={classNames(
                  "transition-color text-nowrap rounded-full px-4 py-2 text-lg font-light transition-colors duration-300 hover:text-brand-text-white ",
                  item.isShow === false ? "hidden" : "",
                  activeTab === index + 1 ? ` ${classMainColor} text-brand-text-white` : "",
                  themeColors[slug as keyof typeof themeColors].hoverBg,
                  activeButton === index ? "active" : ""
                )}
                onClick={(e) => handleClick(e, index + 1)}>
                <Trans i18nKey={item?.title || ""} />
              </button>
            ))}
          </div>
          <div className="mt-5 !max-w-[1160px]">
            {data?.items?.map((item, itemIndex) => {
              if (itemIndex + 1 === activeTab) {
                return (
                  <div key={itemIndex + 1} className="transition-all">
                    {item.blocks?.map((block, blockIndex) => (
                      <div key={blockIndex} className="mt-4 rounded-2xl bg-brand-bg-default p-5 sm:p-10">
                        <h2
                          className={classNames(
                            "mx-auto w-full max-w-[260px] text-center text-[24px] font-semibold sm:max-w-none sm:text-3xl-custom sm:text-5xl",
                            `${classTextColor}`
                          )}>
                          <Trans i18nKey={block.title ?? ""} />
                        </h2>
                        {block.description != "" && (
                          <p className="mx-auto mt-4 text-center text-base font-semibold">
                            <Trans i18nKey={block.description ?? ""} />
                          </p>
                        )}
                        {(() => {
                          if (block.markerType == "bullet" || block.markerType == "digit") {
                            return (
                              <div>
                                {(block?.rows as Row[]).map((row, rowIndex) => (
                                  <div
                                    key={rowIndex}
                                    className="relative flex flex-nowrap  gap-4 pt-3 sm:pb-5 sm:pt-5">
                                    {block.markerType == "bullet" && (
                                      <div className="pt-[7px]">
                                        <div
                                          className={classNames(
                                            `flex h-[7px] w-[7px] flex-shrink-0 items-center justify-center rounded-full ${classMainColor}`
                                          )}
                                        />
                                      </div>
                                    )}
                                    {block.markerType == "digit" && (
                                      <div
                                        className={classNames(
                                          `flex h-[35px] w-[35px] flex-shrink-0 items-center justify-center rounded-full border p-1 text-center text-base font-light leading-none ${classBorderColor} ${classTextColor}`
                                        )}>
                                        <span>{rowIndex + 1}</span>
                                      </div>
                                    )}
                                    <div className={classNames("text-base font-light sm:text-lg")}>
                                      <span>
                                        <Trans i18nKey={row.hasOwnProperty("text") ? row.text : ""} />
                                      </span>
                                      {(row.hasOwnProperty("phone") ||
                                        dataDefault?.items[itemIndex].blocks?.[blockIndex].rows?.[
                                          rowIndex
                                        ].hasOwnProperty("phone")) && (
                                        <Link
                                          className={classNames(
                                            `relative text-lg font-light after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:transition-all after:duration-300 hover:after:w-full `,
                                            classTextColor,
                                            themeColors[slug as keyof typeof themeColors].afterBg
                                          )}
                                          href={`tel:${row?.phone ?? (dataDefault?.items[itemIndex].blocks?.[blockIndex].rows?.[rowIndex] as Row)?.phone ?? ""}`}>
                                          {row?.phone ??
                                            (
                                              dataDefault?.items[itemIndex].blocks?.[blockIndex].rows?.[
                                                rowIndex
                                              ] as Row
                                            )?.phone ??
                                            ""}
                                        </Link>
                                      )}
                                      {(row.hasOwnProperty("text2") ||
                                        dataDefault?.items[itemIndex].blocks?.[blockIndex].rows?.[
                                          rowIndex
                                        ].hasOwnProperty("text2")) && (
                                        <span className={classNames("pl-2 text-lg font-light")}>
                                          <Trans
                                            i18nKey={
                                              row?.text2 ??
                                              (
                                                dataDefault?.items[itemIndex].blocks?.[blockIndex].rows?.[
                                                  rowIndex
                                                ] as Row
                                              )?.text2 ??
                                              ""
                                            }
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                                <p className={classNames("pb-2 text-lg font-light")}>
                                  <Trans i18nKey={block?.filesTitle ?? ""} />
                                </p>
                                {block?.files?.map((file, index) => (
                                  <FileLink
                                    key={index}
                                    slug={slug}
                                    classFillColor={classFillColor}
                                    className={classNames(
                                      `text-left text-xl font-semibold ${classTextColor}`
                                    )}
                                    file={file}
                                  />
                                ))}
                              </div>
                            );
                          } else {
                            return (
                              <div className="mt-0 sm:mt-10">
                                <SectionListForms
                                  list={block?.rows as FormItem[]}
                                  slug={slug}
                                  formLinkTextColor={classTextColor}
                                  classBgColor={classBgColor}
                                  classFillColor={classFillColor}
                                  listDefault={
                                    dataDefault?.items[itemIndex]?.blocks?.[blockIndex].rows as FormItem[]
                                  }
                                />
                              </div>
                            );
                          }
                        })()}
                      </div>
                    ))}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="mt-5 flex flex-col items-center justify-center lg:container lg:mx-auto lg:px-20">
            <p className={classNames(`text-center text-xs font-light ${classTextColor}`)}>
              <Trans i18nKey={data?.footerText1 ?? dataDefault?.footerText1 ?? ""} />
            </p>
            <p
              className={classNames(
                `mt-4 whitespace-pre text-wrap text-center text-xs font-light ${classTextColor}`
              )}>
              <Trans i18nKey={data?.footerText2 ?? dataDefault?.footerText2 ?? ""} />
              <Link
                className={classNames(`ml-1 font-semibold ${classTextColor}`)}
                href={`${data?.footerLinkUrl ?? dataDefault?.footerLinkUrl ?? ""}`}>
                <Trans i18nKey={data?.footerLinkTitle ?? ""} />
              </Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
